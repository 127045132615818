import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import IconButton from "@mui/material/IconButton";
import { useRecoilValue } from "recoil";
import { favoriteProductState } from "../../atoms/favoriteProductsAtoms";
import {
  useDeleteFavoriteProducts,
  useAddFavoriteProducts,
} from "../../hooks/useFavorites";

const FavIcon = ({ sku }) => {
  const data = useRecoilValue(favoriteProductState);
  const hasSku = data.find((item) => item.sku === sku);
  const { mutate: deleteFav } = useDeleteFavoriteProducts();
  const { mutate: addFav } = useAddFavoriteProducts();

  const handleDelete = (evt) => {
    evt.stopPropagation();
    deleteFav(sku);
  };

  const handleAdd = (evt) => {
    evt.stopPropagation();
    addFav(sku);
  };

  if (hasSku) {
    return (
      <IconButton
        onClick={handleDelete}
        sx={{ color: "red", backgroundColor: "var(--gray)" }}
        component="label"
      >
        <FavoriteIcon />
      </IconButton>
    );
  }
  return (
    <IconButton sx={{backgroundColor: "var(--gray)"}} onClick={handleAdd} component="label">
      <FavoriteBorderIcon />
    </IconButton>
  );
};

export default FavIcon;
