import { useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MobileStepper from "@mui/material/MobileStepper";
import CloseIcon from "@mui/icons-material/Close";
import css from "./Gamification.module.css";
import StepIcon from "./StepIcon";
import { GAMIFICATION_STATUS, GAMIFICATION_STEPS } from "./Constants";
import { getUser } from "../../utils/jwt";
import { useNavigate } from "react-router-dom";
import { generateGamificationSteps } from "./helper";
import AnimatedRewardBox from "../AnimatedRewardBox";
import { useUpdateGamingBarStatus } from "../../hooks/useUpdateGamingBarStatus";

function Gamification(props) {
  const { showShadow } = props;
  const [activeStep, setActiveStep] = useState();
  const [gamificationText, setGamificationText] = useState("");
  const [giftMessage, setGiftMessage] = useState(undefined);
  const [showConfetti, setShowConfetti] = useState(false);
  const [completedSteps, setCompletedSteps] = useState(0);
  const { gamingBarStatus } = getUser() || {};

  const [show, setShow] = useState();

  const navigate = useNavigate();
  const { mutate: updateGamingBarStatus } = useUpdateGamingBarStatus();

  useEffect(() => {
    setShow(localStorage.getItem("showGamification") !== "false");
  }, []);

  useEffect(() => {
    const user = getUser();
    const localShowGamification = localStorage.getItem("showGamification");

    if (
      user?.accountType === "pro" &&
      user["gamingBarStatus"] !== GAMIFICATION_STATUS.COMPLETED &&
      (!localShowGamification || !localShowGamification === "false")
    ) {
      localStorage.setItem("smsNotNow", true);
    }
  }, []);

  const gamificationSequence = useMemo(() => {
    const totalSteps = GAMIFICATION_STEPS.length - 1;

    const newSequence = generateGamificationSteps();
    if (!newSequence) {
      return;
    }

    setCompletedSteps(newSequence?.activeStep - 1);
    setGamificationText(
      newSequence?.steps[
        newSequence?.activeStep > totalSteps
          ? totalSteps
          : newSequence?.activeStep
      ].title
    );
    if (newSequence?.steps[newSequence.activeStep]) {
      setGiftMessage(newSequence.steps[newSequence.activeStep].couponMessage);
    }
    return newSequence.steps;
  }, []);

  useEffect(() => {
    if (
      completedSteps === GAMIFICATION_STEPS.length - 1 &&
      gamingBarStatus?.toLowerCase() !== GAMIFICATION_STATUS.COMPLETED
    ) {
      setTimeout(() => {
        updateGamingBarStatus({ status: GAMIFICATION_STATUS.COMPLETED });
        if (gamingBarStatus?.toLowerCase() !== GAMIFICATION_STATUS.EXISTING) {
          setShowConfetti(true);
        }
      }, 2000);
    }
  }, [completedSteps, updateGamingBarStatus, gamingBarStatus]);

  const handleStepIconClick = (step, sequence) => {
    setActiveStep(sequence);
    setGamificationText(step.title);
    setGiftMessage(step.couponMessage ? step.couponMessage : "");
  };

  if (
    !show ||
    !gamificationSequence ||
    gamificationSequence.length === 0 ||
    gamingBarStatus?.toLowerCase() === GAMIFICATION_STATUS.COMPLETED
  ) {
    return;
  }

  if (!gamificationSequence) {
    return;
  }

  const handleClose = (e) => {
    setShow(false);
    localStorage.setItem("showGamification", false);
    e.stopPropagation();
  };

  if (showConfetti) {
    return <AnimatedRewardBox show={true} />;
  }

  return (
    <Grid
      container
      xs={12}
      mt={1}
      mb={1}
      className={
        showShadow ? css["gamification-main-shadow"] : css["gamification-main"]
      }
    >
      <Grid item container xs={11} className={css["stepper"]}>
        <Grid item className={css["gamification-steps"]}>
          {gamificationSequence?.map((step, index) => {
            return (
              <StepIcon
                step={step}
                active={
                  activeStep || activeStep === 0
                    ? activeStep
                    : completedSteps + 1
                }
                sequence={index}
                stepIconClick={handleStepIconClick}
                showGift={
                  gamingBarStatus?.toLowerCase() !==
                  GAMIFICATION_STATUS.EXISTING
                }
              />
            );
          })}
        </Grid>
        <Grid item className={css["gamification-stepper"]}>
          <MobileStepper
            variant="progress"
            steps={6}
            position="static"
            activeStep={completedSteps === 0 ? 0.125 : completedSteps}
            sx={{
              flexGrow: 1,
              "& .MuiMobileStepper-progress": {
                width: "100%",
                height: "10px",
                borderRadius: "1rem",
                background:
                  "linear-gradient(90deg, rgba(184, 161, 201, 0.5) 0%, rgba(191, 202, 221, 0.5) 27%, rgba(181, 205, 248, 0.5) 53.5%, rgba(212, 188, 178, 0.5) 76.5%, rgba(254, 149, 101, 0.5) 97%)",
              },
              "& .MuiLinearProgress-bar": {
                backgroundColor: "unset",
                background: "linear-gradient(90deg, #BFCADD 0%, #B8A1C9 100%)",
                borderRadius: "1rem",
              },
            }}
          />

        </Grid>
      </Grid>
      <Grid
        item
        xs={11}
        className={
          css[
            completedSteps === GAMIFICATION_STEPS.length - 1
              ? "status-complete"
              : "status"
          ]
        }
        onClick={() => navigate("/onboarding")}
      >
        {completedSteps === GAMIFICATION_STEPS.length - 1 ? (
          <p className={css["task-complete"]}>
            All tasks completed. Well done!
          </p>
        ) : (
          <>
            <div className={css["icon-message"]}>
              <div className={css["cart-icon-parent"]}>
                <ShoppingCartOutlinedIcon className={css["cart-icon"]} />
              </div>
              <p style={{ padding: "unset" }}>{gamificationText}</p>
            </div>
            <div className={css["close-icon"]}>
              <CloseIcon fontSize="small" onClick={handleClose} />
            </div>
          </>
        )}
      </Grid>
      {giftMessage?.length > 0 &&
        gamingBarStatus?.toLowerCase() !== GAMIFICATION_STATUS.EXISTING &&
        !showConfetti && (
          <Grid item xs={11}>
            <div
              className={css["gift-message"]}
              onClick={() => navigate("/onboarding")}
            >
              <p style={{ padding: "unset" }}>{giftMessage}!</p>
            </div>
          </Grid>
        )}
    </Grid>
  );
}

export default Gamification;
